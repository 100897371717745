export default {
  group: [
    {
      name: 'personal_data',
      fields: [
        {
          name: 'title',
          type: 'text',
          column_type: 'two-third-row',
        },
        {
          name: 'star_category',
          type: 'rate',
          column_type: 'one-third-row',
        },
        {
          name: 'name_of_legal_entity',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'code_rnokpp',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_main',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'code_main_department',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'head_full_name',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'building_info',
      fields: [
        {
          name: 'house_type',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'house_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'building_type',
          type: 'select',
          column_type: 'one-third-row',
        },
        {
          name: 'building_number',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'address_title',
      fields: [
        {
          name: 'population_item',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'district',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'region',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'street',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'zip',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'katottg',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'mail_address',
      fields: [
        {
          name: 'mail_address',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'activity_type',
      fields: [
        {
          name: 'activity_kinds',
          type: 'select',
          column_type: 'full-row',
        },
        {
          name: 'record_number',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'primary_activity_kind_name',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'registration',
          type: 'date',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'subject_info',
      fields: [
        {
          name: 'subject_status',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'date_of_state_registration',
          type: 'date',
          column_type: 'one-third-row',
        },
        {
          name: 'subject_status_description',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'history',
      fields: [
        {
          name: 'certificate_series',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_validity',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_term_end',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'certificate_term_start',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'notes',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'title',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'number_fund',
      fields: [
        {
          name: 'number_of_single_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_double_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_triple_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_quadruple_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'number_of_more_rooms',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'total_beds_quantity',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'conference_halls',
      fields: [
        {
          name: 'conference_halls',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'total_capacity',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
    {
      name: 'state_standard',
      fields: [
        {
          name: 'dstu',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
